<template>
  <div :class="['model-page__tabs _flex', { comparison: isComparison }]">
    <div
      v-for="(item, index) in tabs"
      :key="item.componentName"
      :class="[
        'model-page__tabs__item _font-weight__600 _font-size__sub__heading _pointer',
        { active: index == tabsIndex }
      ]"
      @click="handleTabClick(item, index)"
    >
      {{ item.label }}
    </div>

    <!-- 红色线 -->
    <div class="model-page__tabs__border" :style="borderStyle"></div>
  </div>
</template>

<script>
import common from '@/common'
import { tabs, getDom } from '../props'

export default {
  data() {
    return {
      isComparison: false,
      componentName: 'tilt-sensor',
      domPosition: [],
      tabsIndex: 0,
      tabs,
      trigger: ''
    }
  },
  mounted() {
    this.$nextTick(getDom.bind(this))
    this.$bus.$on('sync-data', (val) => {
      if (typeof val.isComparison === 'boolean') this.isComparison = val.isComparison
      if (val.tabsIndex) this.tabsIndex = val.tabsIndex
    })
    this.$bus.$on('model-change', (bol) => {
      console.log('触发model-change tower-tabs中')
      this.isComparison = bol
      this.trigger = common.TRIGGER_TYPE.EVENT
      if (bol) {
        this.tabsIndex = this.tabsIndex === 0 ? 1 : this.tabsIndex
      }
      setTimeout(() => {
        this.$bus.$emit('route-nav', tabs[this.tabsIndex], {
          source: 'model-tower-nav',
          trigger: this.trigger
        })
      })
    })
  },
  computed: {
    borderStyle() {
      if (this.domPosition.length === 0) return { width: '0px' }
      const style = {
        width: `${this.domPosition[this.tabsIndex].width}px`,
        transform: `translateX(${this.domPosition[this.tabsIndex].left}px)`
      }

      return style
    }
  },
  methods: {
    handleTabClick(tab, index) {
      if ((index === 0 && this.isComparison) || this.tabsIndex === index) return
      this.tabsIndex = index
      this.trigger = common.TRIGGER_TYPE.CLICK
      this.componentName = tab.componentName

      this.$bus.$emit('route-nav', tab, {
        source: 'model-tower-nav',
        trigger: this.trigger
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.model-page {
  width: calc(100vw - var(--menu-width));
  height: var(--nav-height);
  padding: 0 40px;
  background: var(--color-white);
  z-index: 2;
  &__name {
    i {
      margin-right: 15px;
    }
  }

  &__tabs {
    position: relative;
    height: 35px;

    &__item {
      transition: all 0.3s;
      line-height: 35px;
      &.active {
        color: var(--color-primary);
      }
    }

    &__item + .model-page__tabs__item {
      margin-left: 40px;
    }

    &__border {
      position: absolute;
      transition: all 0.3s;
      bottom: 0;
      left: 0;
      height: 3px;
      background: var(--color-primary);
    }
  }
}

.comparison {
  .model-page__tabs__item {
    border-radius: 50px;
  }
  .model-page__tabs__item:first-of-type {
    background: #1f97f5;
    color: var(--color-white);
    padding: 0 10px;
  }
  .model-page__tabs__border {
    background: rgba(0, 0, 0, 0);
    transition: all 0s;
  }
  .active {
    background: var(--color-primary);
    color: var(--color-white);
    padding: 0 10px;
  }
}
</style>
